import React, { useEffect, useState } from "react";
import "./header.css";
import "./switch.css";

const Header = () => {
    /* ========== Change background header ========== */
    window.addEventListener("scroll", function () {
        const header = document.querySelector(".header");
        if (this.scrollY >= 80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header");
    });

    /* ========== Toggle menu ========== */
    const [Toggle, showMenu] = useState(false);
    const [activeNav, setActiveNav] = useState("#home");

    /* ========== Toggle dark mode ========== */
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const prefersDarkMode =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;
        const savedMode = localStorage.getItem("isDarkMode");
        return savedMode ? savedMode === "true" : prefersDarkMode;
    });

    useEffect(() => {
        document.body.classList.toggle("dark-mode", isDarkMode);
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        const newIsDarkMode = !isDarkMode;
        setIsDarkMode(newIsDarkMode);
        localStorage.setItem("isDarkMode", newIsDarkMode.toString());
        document.body.classList.toggle("dark-mode", newIsDarkMode);
    };

    return (
        <header className="header">
            <nav className="nav container">
                <a href="index.html" className="nav__logo">
                    &lt;SK /&gt;
                </a>

                <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <a
                                href="#home"
                                onClick={() => setActiveNav("#home")}
                                className={
                                    activeNav === "#home"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-estate nav__icon"></i>{" "}
                                Home
                                {activeNav === "#home" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffset="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>

                        <li className="nav__item">
                            <a
                                href="#about"
                                onClick={() => setActiveNav("#about")}
                                className={
                                    activeNav === "#about"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-user nav__icon"></i> About
                                {activeNav === "#about" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffset="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>

                        <li className="nav__item">
                            <a
                                href="#skills"
                                onClick={() => setActiveNav("#skills")}
                                className={
                                    activeNav === "#skills"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-file nav__icon"></i>{" "}
                                Skills
                                {activeNav === "#skills" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffset="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>

                        {/* <li className="nav__item">
                            <a
                                href="#services"
                                onClick={() => setActiveNav("#services")}
                                className={
                                    activeNav === "#services"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-briefcase-alt nav__icon"></i>{" "}
                                Services
                            </a>
                        </li> */}

                        <li className="nav__item">
                            <a
                                href="#portfolio"
                                onClick={() => setActiveNav("#portfolio")}
                                className={
                                    activeNav === "#portfolio"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-scenery nav__icon"></i>{" "}
                                Portfolio
                                {activeNav === "#portfolio" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffsett="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>

                        <li className="nav__item">
                            <a
                                href="#projects"
                                onClick={() => setActiveNav("#projects")}
                                className={
                                    activeNav === "#projects"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-briefcase-alt nav__icon"></i>{" "}
                                Work
                                {activeNav === "#projects" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffset="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>

                        <li className="nav__item">
                            <a
                                href="#contact"
                                onClick={() => setActiveNav("#contact")}
                                className={
                                    activeNav === "#contact"
                                        ? "nav__link active-link"
                                        : "nav__link"
                                }
                            >
                                <i className="uil uil-message nav__icon"></i>{" "}
                                Contact
                                {activeNav === "#contact" && (
                                    <svg
                                        width="37"
                                        height="8"
                                        viewBox="0 0 37 8"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 5.39971C7.48565 -1.08593 6.44837 -0.12827 8.33643 6.47992C8.34809 6.52075 11.6019 2.72875 12.3422 2.33912C13.8991 1.5197 16.6594 2.96924 18.3734 2.96924C21.665 2.96924 23.1972 1.69759 26.745 2.78921C29.7551 3.71539 32.6954 3.7794 35.8368 3.7794"
                                            stroke="var(--text-color)"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeDasharray="84.20591735839844"
                                            strokeDashoffset="0"
                                            className="underline-path"
                                        ></path>
                                    </svg>
                                )}
                            </a>
                        </li>
                        <label className="ui-switch">
                            <input
                                type="checkbox"
                                onChange={toggleDarkMode}
                                checked={isDarkMode}
                            />
                            <div className="slider">
                                <div className="circle"></div>
                            </div>
                        </label>
                    </ul>

                    <i
                        className="uil uil-times nav__close"
                        onClick={() => showMenu(!Toggle)}
                    ></i>
                </div>

                <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                    {!Toggle && <i className="uil uil-apps"></i>}
                </div>
            </nav>
        </header>
    );
};

export default Header;
