import React from "react";

const Backend = () => {
    return (
        <div className="skills__content">
            <h3 className="skills__title">Currently Learning</h3>

            <div className="skills__box">
                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxl-aws"></i>

                        <div>
                            <h3 className="skills__name">AWS</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bxl-docker"></i>

                        <div>
                            <h3 className="skills__name">Docker</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bxl-google-cloud"></i>

                        <div>
                            <h3 className="skills__name">GoogleCloud</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Express.js</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bxl-kubernetes"></i>

                        <div>
                            <h3 className="skills__name">Kubernetes</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>
                </div>

                <div className="skills__group">
                    <div className="skills__data">
                        <i className="bx bxl-mongodb"></i>

                        <div>
                            <h3 className="skills__name">MongoDB</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bxl-nodejs"></i>

                        <div>
                            <h3 className="skills__name">Node.js</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Next.js</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bx-badge-check"></i>

                        <div>
                            <h3 className="skills__name">Nginx</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <i className="bx bxl-react"></i>

                        <div>
                            <h3 className="skills__name">React</h3>
                            <span className="skills__level">Self studying</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Backend;
