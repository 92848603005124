import React from "react";
import "./footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">&lt;SK /&gt;</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">
                            About
                        </a>
                    </li>

                    <li>
                        <a href="#portfolio" className="footer__link">
                            Portfolio
                        </a>
                    </li>

                    <li>
                        <a href="#projects" className="footer__link">
                            Projects
                        </a>
                    </li>
                </ul>

                <div className="footer__social">
                    <a
                        href="https://www.facebook.com/shakila.kamalasena"
                        className="footer__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="bx bxl-facebook"></i>
                    </a>
                    
                    <a
                        href="https://www.instagram.com/doinkcozyy"
                        className="footer__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="bx bxl-instagram"></i>
                    </a>
                    
                    <a
                        href="https://twitter.com/doinkcozyy"
                        className="footer__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="bx bxl-twitter"></i>
                    </a>
                    
                    <a
                        href="https://www.linkedin.com/in/shakila-kamalasena-720038103/"
                        className="footer__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="bx bxl-linkedin"></i>
                    </a>
                    
                    <a
                        href="https://github.com/shakilakamalasena"
                        className="footer__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="bx bxl-github"></i>
                    </a>
                </div>

                <span className="footer__copy">
                    {/* Developed with &#x2661; by Shakila Kamalasena @ 2023 */}
                    &copy; 2023 Shakila Kamalasena
                </span>
            </div>
        </footer>
    );
};

export default Footer;
