import React, { useCallback, useEffect, useState } from "react";
import querystring from "querystring";
import { Buffer } from "buffer";
import { BiErrorCircle } from "react-icons/bi";
import { HiOutlineStatusOffline } from "react-icons/hi";
import { SlSocialSpotify } from "react-icons/sl";
import { MdMotionPhotosPaused } from "react-icons/md";
import soundbarGif from "../../assets/soundbar.gif";
import "./spotifyWidget.css";

//Setting up the Spotify API and Endpoints
const NOW_PLAYING_ENDPOINT =
    "https://api.spotify.com/v1/me/player/currently-playing";
const TOKEN_ENDPOINT = "https://accounts.spotify.com/api/token";
const client_id = "85ad101c8f864f19aa4f71aec2f061a0";
const client_secret = "7846be54d0194ed59022ea79c10a9e4d";
const refresh_token =
    "AQBLAlwyBXzwIt61yWOAt5Q3yvvD7NiZeRsQK4cXYbCrG-zDAekH-Rqv5yaTkBXrrzi2XhghQvT3MDAMYm9Zf71lvKN1fzPXXyxTOg7UVOuCNNodPA9czbCj-l1TP2VBnuU";

//Function to generate an access token using the refresh token everytime the website is opened or refreshed
export const getAccessToken = async (
    client_id,
    client_secret,
    refresh_token
) => {
    //Creates a base64 code of client_id:client_secret as required by the API
    const basic = Buffer.from(`${client_id}:${client_secret}`).toString(
        "base64"
    );

    //The response will contain the access token
    const response = await fetch(TOKEN_ENDPOINT, {
        method: "POST",
        headers: {
            Authorization: `Basic ${basic}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: querystring.stringify({
            grant_type: "refresh_token",
            refresh_token,
        }),
    });

    return response.json();
};

//Uses the access token to fetch the currently playing song
export const getNowPlaying = async () => {
    try {
        //Generating an access token
        const { access_token } = await getAccessToken(
            client_id,
            client_secret,
            refresh_token
        );

        //Fetching the response
        const response = await fetch(NOW_PLAYING_ENDPOINT, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        //If response status > 400 means there was some error while fetching the required information
        if (response.status > 400) {
            throw new Error("Unable to Fetch Song");
        } else if (response.status === 204) {
            //The response was fetched but there was no content
            throw new Error("Currently Not Playing");
        }

        //Extracting the required data from the response into seperate variables
        const song = await response.json();
        const artist = song.item.artists
            .map((artist) => artist.name)
            .join(", ");
        const isPlaying = song.is_playing;
        const title = song.item.name;

        //Returning the song details
        return {
            artist,
            isPlaying,
            title,
        };
    } catch (error) {
        // console.error("Error fetching currently playing song: ", error);
        return error.message.toString();
    }
};

const SpotifyWidget = () => {
    //Hold information about the currently playing song
    const [nowPlaying, setNowPlaying] = useState(null);

    const fetchNowPlaying = useCallback(async () => {
        const data = await getNowPlaying();

        if (data && (nowPlaying === null || data.title !== nowPlaying.title)) {
            setNowPlaying(data);
        }
    }, [nowPlaying]);

    useEffect(() => {
        fetchNowPlaying();
        const interval = setInterval(fetchNowPlaying, 30000);

        return () => clearInterval(interval);
    }, [fetchNowPlaying]);

    //Setting default values for the listener's current state and the duration of the song played
    let playerState = "";
    let title = "";
    let artist = "";

    if (nowPlaying != null && nowPlaying.title) {
        //Used while displaing a sounbar/pause icon on the widget
        playerState = nowPlaying.isPlaying ? "PLAY" : "PAUSE";

        title = nowPlaying.title + " by " + nowPlaying.artist + " - on Spotify";
        artist = nowPlaying.artist;
    } else if (nowPlaying === "Currently Not Playing") {
        //If the response returns this error message then we print the following text in the widget
        playerState = "OFFLINE";
        title = "Not listening - Spotify";
        artist = "No song playing in spotify";
    } else {
        //If the response wasn't able to fetch anything then we display this
        // title = "Failed to fetch song";
        // artist = "error";
        playerState = "OFFLINE";
        title = "Not listening - Spotify";
        artist = "No song playing in spotify";
    }

    return (
        <div className="spotifyWidget">
            {/* Depending on the value of playerState, the href, album image and icons are updated */}
            <button
                style={{
                    textDecoration: "none",
                    color: "black",
                    border: "none",
                    background: "none",
                    padding: "0",
                    cursor: "pointer",
                }}
            >
                <div className="nowPlayingCard">
                    {/* Albumn image and href displayed based on playerState */}
                    <div className="nowPlayingImage">
                        <div className="spotifyIcon">
                            <SlSocialSpotify size={25} />
                        </div>
                    </div>
                    <div id="nowPlayingDetails">
                        {/* Song Title displayed based on playerState */}
                        <div
                            className={`nowPlayingTitle ${
                                title.length > 15 ? "marquee" : ""
                            }`}
                        >
                            <div
                                className={`nowPlayingTitleInner ${
                                    title.length > 15 ? "marquee-content" : ""
                                }`}
                            >
                                {title}
                            </div>
                        </div>
                    </div>
                    {/* Icon displayed based on playerState */}
                    <div className="nowPlayingState">
                        {playerState === "PLAY" ? (
                            <img
                                src={soundbarGif}
                                alt="Soundbar"
                                className="soundbar"
                            />
                        ) : playerState === "PAUSE" ? (
                            <MdMotionPhotosPaused size={25} />
                        ) : playerState === "OFFLINE" ? (
                            <HiOutlineStatusOffline size={25} />
                        ) : (
                            <BiErrorCircle size={30} />
                        )}
                    </div>
                </div>
            </button>
        </div>
    );
};

export default SpotifyWidget;
