import React from "react";
import "./projects.css";
import { Data } from "./Data";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const Projects = () => {
    return (
        <section className="project container section" id="projects">
            <h2 className="section__title">Projects I did</h2>
            <span className="section__subtitle">Projects</span>

            <Swiper
                className="project__container"
                loop={true}
                grabCursor={true}
                spaceBetween={24}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        // spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 48,
                    },
                    // 1024: {
                    //     slidesPerView: 3,
                    //     spaceBetween: 50,
                    // },
                }}
                modules={[Pagination]}
            >
                {Data.map(({ id, image, title, status, description, link }) => {
                    return (
                        <SwiperSlide className="project__card" key={id}>
                            <img src={image} alt="" className="project__img" />

                            <h3 className="project__name">{title}</h3>
                            <p className="project__description">
                                {description} <br />
                                {status === "ongoing" ? (
                                    <span className="project__button">
                                        Coming Soon
                                        <i className="uil uil-clock"></i>
                                    </span>
                                ) : status === "goto" ? (
                                    <a
                                        href={link}
                                        className="project__button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Go to Website
                                        <i className="uil uil-external-link-alt project__button-icon"></i>
                                    </a>
                                ) : (
                                    <a
                                        href={link}
                                        className="project__button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        View More
                                        <i className="uil uil-arrow-right project__button-icon"></i>
                                    </a>
                                )}
                            </p>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </section>
    );
};

export default Projects;
